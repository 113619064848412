import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header from './Header';
import Deposits from './Deposits';
import Orders from './Orders';
import Graph from './Graph';
import Charts from '../../Charts';

const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Home (props){
    
    let defrif = props.rif
    let defrazon = props.razon
    let deftoken = props.token
    let page = props.page
   
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
 
  
    return(
      <Grid container spacing={3}>
      {/* Gestionar usuarios */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <Header />
        </Paper>
      </Grid>
      {/* Total trx */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Deposits rif = {defrif} token ={deftoken}/>
        </Paper>
      </Grid>
      {/* Transacciones */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
         {page==="home"?<Orders rif = {defrif} razon = {defrazon} token = {deftoken}/>: <Graph rif = {defrif} razon = {defrazon} token = {deftoken} /> } 
        </Paper>
      </Grid>
    </Grid>
  ) 
}  