import React from 'react';
import { ResponsiveContainer,LineChart,CartesianGrid,XAxis,YAxis,Tooltip,Line } from 'recharts';
import { Container } from 'react-grid-system';

import {BaseURL, getTrxMonth} from '../../../constants/constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  titleChart:{
    fontWeight:'bold'
  }
}))

export default function Graph(props) {
    
    const classes = useStyles();
    let rifMerchant = props.rif
    let rif = rifMerchant

    let apiBaseUrl =BaseURL;

    const [datachar, setStateDataChart]=React.useState({
        chartData: [],
      })
    let schema = {
      rif:rif
    }
    let header={
      headers:{
        Authorization: props.token
      }
    }
    React.useEffect((props) => {
    
        axios.post(apiBaseUrl+getTrxMonth,schema,header)
        .then(res => {

          const data = res.data.message
         
          setStateDataChart({chartData:data})
          
        })
        .catch(err=>{
          console.log(err)
        })
      
      },[])
    return (
        <React.Fragment>
          
          <ResponsiveContainer width={700} height="80%">
            <Container>
              <Typography className= {classes.titleChart}>
                GRÁFICO DE OPERACIONES
              </Typography>
              <LineChart width={1010} height={200}  data={datachar.chartData}
                margin={{ top: 40, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"MONTH"} />
                <YAxis />
                <Tooltip />
                
                <Line type="monotone" dataKey="numRecords" stroke="#8884d8" label="Nro"  />
                <Line type="monotone" dataKey="sumTrx" stroke="#82ca9d" label="Total" />
              </LineChart>
            </Container>
          </ResponsiveContainer>
        </React.Fragment>
      );
}