import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import {BaseURL, sendMailAdmin} from '../constants/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://nextpos.us/">
          NextPOS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      
      backgroundImage : 'linear-gradient(to right, rgb(102, 48, 194), rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundImage : 'linear-gradient(to right, rgb(102, 48, 194), rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))'
      },
      buttonProgress: {
        color: "#1f2d3d",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      }
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
    export default function ForgotPassword(){
        const classes = useStyles();

        const [email, setEmail] = useState("")
        const [emptymail, setEmptyMail] = useState(false)
        const [loader,setLoader] = useState(false)
        const [onCheck]=useState(false)
        const [open, setOpen] =useState(false);
        const [msg, setMsgs]= useState("")

        function resetPassword(event){
          
          var ismailEmpty = isEmpty(email)
          if(ismailEmpty ||  onCheck ===false){
            return
          }
          setLoader(true)
          var payload= {
            mail: email
          }
          axios.post(BaseURL+sendMailAdmin, payload)
          .then(function (response) {
            setLoader(false)
                if(response.data.Exito){
                  setMsgs(response.data.Message)
                  
                  handleClickOpen()
                }
                else{   
                  setMsgs(response.data.Message)
                  handleClickOpen()
                }
               
          })
          .catch(function (error) {
            setLoader(false)
            setMsgs("Error en el servidor")
          });
            //history.push("/resetpassword");
        }
        function isEmpty(field){
          if(field===""){
            setEmptyMail(true)
            return true
          }else{
            setEmptyMail(false)
            return false
          }
        }

        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
          document.getElementById("email").value = ""
        };
    return (
      <React.Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ¡Recuperar contraseña!
          </Typography>

        <form className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
                    <TextField
                    error = {emptymail}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Correo electrónico"
                    value={email}
                    name="email"
                    autoComplete="email"
                    disabled = {loader}
                    inputProps={{maxLength: 30}}
                    onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled = {loader}
              onClick={(event) => resetPassword(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Enviar
            </Button>

        </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
        </Container>
      </React.Fragment>
       
    );
}
