import React from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import {BaseURL,getAllTransactions,getTransactionsByDate} from '../../../constants/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import PdfDocument from './PdfDocument';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  
  buttonProgress: {
    color: "#000048",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  titleTable:{
    fontWeight:'bold'
  },

  successText:{
    color:'green'
  },
  pendingText:{
    color:'orange'
  },
  rejectText:{
    color : 'red'
  }
  
}));
export default function Orders(props) {
  const classes = useStyles();
  let rifMerchant = props.rif
  let razon_social = props.razon
  let token = props.token
  const [open, setOpen] =React.useState(false);
  const [openDownloader, setOpenDownloader] = React.useState(false)
  const [loader,setLoader] = React.useState(false)
  const [setDateError] = React.useState(false)
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date());
  const [table, setStateTable]=React.useState({
    data: [
      //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
      //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
    ],
  })
React.useEffect((props) => {
  
  setLoader(true)
  axios.post(BaseURL+getAllTransactions,{rif:rifMerchant},{headers:{Authorization:token}})
  
  .then(res => {
      setLoader(false)
      if (res.data[0] !== undefined) {
         let datos  = res.data
         
         datos.forEach(function(e){
          if(e.estatus === 1){
            e["estatus"] = <label  className ={classes.successText}>
                                  Aprobado
                           </label>
          }else if (e.estatus ===2){
            e["estatus"] = <label href="#" className ={classes.rejectText}>
                              Rechazado
                           </label> 
          }
         })
         setStateTable({data:datos})
          return res.data;
        } else {
          return 'Null';
        }

  })
  .catch(err=>{
    setLoader(false)
    console.log(err)
  })

},[])
const handleDateChangeTo = date =>{
  setSelectedDateTo(date)
};


const handleDateChangeFrom = date =>{
  setSelectedDateFrom(date)
};
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const getTransactionDate = ()=>{
  let to = document.getElementById("toTrx")
  let from = document.getElementById("fromTrx")
 
  let dateTrim= to.value+"-"+from.value
  let dateFormat = dateTrim.split("-")
  let toWithoutFormat = dateFormat[0].replace(/[/]/g," ")
  let fromWthoutFormat = dateFormat[1].replace(/[/]/g," ")
  let header={headers:{Authorization:token}}
  if(selectedDateTo > selectedDateFrom){
    setDateError(true)
    return
  }
  
  let schema = {
    rif:rifMerchant,
    fecha:toWithoutFormat+"-"+fromWthoutFormat
  }
  
  handleClose()
  setLoader(true)
  axios.post(BaseURL+getTransactionsByDate,schema,header)
  .then(res => {
   
    setLoader(false)
    let datos  = res.data.message
    
    datos.forEach(function(e){
      if(e.estatus === 1){
        e["estatus"] = <label className ={classes.successText}>
                              Aprobado
                       </label>
      }else if (e.estatus ===2){
        e["estatus"] = <label className ={classes.rejectText}>
                          Rechazado
                       </label> 
      }

      e["aplicacion"] = e.aplicacion
     })
          setStateTable({data:datos})
         
          return res.data
      
  })
  .catch(err=>{
   
    setLoader(false)
    console.log(err)
  })
};

const handleOpenDowloader = ()=>{
  setOpenDownloader(true)
};

const handleCloseDownloader = ()=>{
  setOpenDownloader(false)
};
  return (
  
  <React.Fragment>   
       <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Buscar"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Seleccione el rango de fecha
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="toTrx"
                  label="Desde"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  //error ={dateError}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="fromTrx"
                  label="Hasta"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Buttons onClick = {getTransactionDate} color="primary">
              Ir
            </Buttons>
          </DialogActions>
        </Dialog>
                
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={openDownloader}
         
          onClose={handleClose}
          message="Descargar archivo"
          action={
            <React.Fragment>
              
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                {openDownloader &&<PDFDownloadLink
                document={<PdfDocument data={table} razon = {razon_social} />}
                fileName="Reporte.pdf"
                style={{
                  textDecoration: "none",
                  padding: "5px",
                  color: "white",
                  backgroundColor: "transparent",
                  
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (<CircularProgress style={{height:20, width:20}} color="green" /> )  : (<GetAppIcon onClick= {handleCloseDownloader}/>)
            }
            </PDFDownloadLink>}
                
              </IconButton>
            </React.Fragment>
          }
      />
    <MaterialTable
      title={<Typography className = {classes.titleTable}>TRANSACCIONES</Typography>}
      isLoading = {loader}
      columns={
        [
          { title: 'Fecha', field: 'fecha' },
          { title: 'Monto Bs', field: 'monto' },
          { title: 'Nro Aprobación', field: 'aprobacion' },
          { title: 'Nro Referencia',field: 'num_referencia'},
          { title: 'Lote',field: 'lote'},
          { title: 'últimos 4 digitos tarjeta',field:'ultimos4_digitos'},

          { title: 'Estatus', field:'estatus'}
        ]
        
      }
      data={table.data}
      options={{
        headerStyle: {
          backgroundColor: "#6730E3",
          color: '#FFF'
        }
      }}
      actions={[
        {
          icon: DateRangeIcon,
          tooltip: 'Buscar fecha',
          isFreeAction: true,
          onClick: handleClickOpen,
        },
        { icon: GetAppIcon,
          tooltip: 'Descargar PDF',
          isFreeAction: true,
          onClick: handleOpenDowloader}
      ]}
    >
      
    </MaterialTable>
    </React.Fragment>
  );

  
}