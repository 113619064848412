import React from 'react';
import clsx from 'clsx';
import { makeStyles,withStyles  } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems } from './listItems';
import {  Route } from "react-router-dom"
import Home from './componentsSPA/homeComponents/Home'
import CryptoJS from 'crypto-js'
import { MasterKeyLogin } from '../constants/constants'
import Plink from '../assets/logowhite.png'
import { useHistory } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import HomeCharts from './componentsSPA/homeComponents/HomeCharts';
import { Avatar, SwipeableDrawer } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useMediaQuery } from 'react-responsive';

function Copyright() {
    return ( 
    <Typography variant = "body2" color = "textSecondary"align = "center" > { 'Copyright © ' } 
        <Link color = "inherit" href = "https://material-ui.com/" >
            NextPOS 
        </Link>{' '} { new Date().getFullYear() } { '.' } 
    </Typography>
    );
}

const drawerWidth = 240;

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#00c853',
      color: '#00c853',
      marginRight:8,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed

    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        backgroundColor: "rgb(102, 48, 194)"
    },
    toolbarIconTitle: {
        position: "absolute",
        top: "15px",
        right: "60px",
        width:120,
        height:35
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
        
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down("xs")]:{
            width: '100%',
        }

    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
        [theme.breakpoints.down("xs")]:{
            display: 'block',
        }
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down("xs")]:{
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    avatar:{
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        marginRight:10
    }
}));

export default function Dashboard() {
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
    const [open, setOpen] = React.useState(false);
   // const []
    let history = useHistory();
    
    let rif = localStorage.getItem("_CDAUPOMGKLJL") || sessionStorage.getItem("_CDAUPOMGKLJL")
    let razon = localStorage.getItem("_CDAUPOMGKLJLTYJIKHhkuiok") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHhkuiok")
    let token = localStorage.getItem("_{}KRTKLMNHFGFBJNMKKDKDDM") || sessionStorage.getItem("_{}KRTKLMNHFGFBJNMKKDKDDM")
    let image = localStorage.getItem("_{}4RThhUJHHUKNMJKK") || sessionStorage.getItem("_{}4RThhUJHHUKNMJKK")

    let defrif;
    let deftoken;
    let defimg;
    let defrazon;

    
    /*validar sesion*/
    if (rif === null) {
        history.push("/login")
    } else {
        let decryptRif = CryptoJS.AES.decrypt(rif, MasterKeyLogin)
        defrif = decryptRif.toString(CryptoJS.enc.Utf8);

        let decryptRazon = CryptoJS.AES.decrypt(razon, MasterKeyLogin)
        defrazon = decryptRazon.toString(CryptoJS.enc.Utf8);

        let decryptToken = CryptoJS.AES.decrypt(token.toString(), MasterKeyLogin )
        deftoken = decryptToken.toString(CryptoJS.enc.Utf8)

        let decryptImage = CryptoJS.AES.decrypt(image.toString(), MasterKeyLogin )
        defimg = decryptImage.toString(CryptoJS.enc.Utf8)
    }


    

    const classes = useStyles();
    
    const handleDrawerOpen = event => {
        event.preventDefault();
        
        setOpen(true);
    };



    const handleDrawerClose = event => {
        event.preventDefault();
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(false);
    };

    const logout = event => {

        localStorage.clear() || sessionStorage.clear()
        window.location.reload();
    };

    

    return ( 
        <div className = { classes.root } >
                <CssBaseline />
                <AppBar position = "absolute"className = { clsx(classes.appBar, open && classes.appBarShift) } >
                <Toolbar className = { classes.toolbar } >

                <IconButton edge = "start" 
                    color = "inherit" 
                    aria-label = "open drawer"
                    onClick = { handleDrawerOpen } 
                    className = { clsx(classes.menuButton, open && classes.menuButtonHidden) } >
                    
                    <MenuIcon />

                </IconButton> 
                <Typography component = "h1"
                    variant = "h6"
                    color = "inherit"
                    noWrap className = { classes.title } >
                
                </Typography>
               
                <Badge color="secondary"  badgeContent={0} invisible={true} showZero style={{marginRight:20}} >
                    <NotificationsIcon fontSize="large" />
                </Badge>
                
                <Tooltip title={
                                <React.Fragment>
                                    <Typography color="inherit">{defrazon}</Typography>
                                    <b style={{fontSize:15}}><em>{"RIF: "+defrif}</em></b>    
           
                                </React.Fragment>}
                >
                    <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        variant="dot"
                        
                    >
                        <Avatar className={classes.avatar} src={defimg}/>
                    </StyledBadge>
                </Tooltip>  
                
                <Tooltip title = "cerrar sesión" >
                <IconButton color = "inherit"
                onClick = { logout }
                type = "submit" >
                
                <ExitToAppIcon />

                </IconButton> 
                </Tooltip>

                </Toolbar> 
                </AppBar> 
                {
                    isDesktopOrLaptop? 
                    <Drawer variant = "permanent"
                classes = {
                    {
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }
                }
                open = { open } >
                    <div className = { classes.toolbarIcon } >
                        <img src = {Plink} className = { classes.toolbarIconTitle } alt="Plink"/>
                            <IconButton onClick = { handleDrawerClose } >
                                <ChevronLeftIcon />
                            </IconButton> 
                    </div> 
                    
                    <Divider />
                        <div
                        onClick={handleDrawerClose}
                        onKeyDown={handleDrawerClose}
                        >
                            <List > { mainListItems } </List> 
                        </div>
                       
                    <Divider />

                </Drawer>:
                
                <SwipeableDrawer
                    classes={ {paper: clsx(classes.drawerPaper)}}
                    anchor='left'
                    open={open}
                    onClose={handleDrawerClose}
                    
                >
                <div className = { classes.toolbarIcon } >
                        <img src = {Plink} className = { classes.toolbarIconTitle } alt="Plink"/>
                            <IconButton onClick = { handleDrawerClose } >
                                <ChevronLeftIcon />
                            </IconButton> 
                    </div> 
                    
                    <Divider />
                        <List> { mainListItems } </List> 
                    <Divider />
                </SwipeableDrawer>
                }
                 
                <main className = { classes.content } >
                <div className = { classes.appBarSpacer }/> 
                <Container maxWidth = "lg"
                className = { classes.container } >
                        <Route 
                        path = '/home'
                        render = {
                            (props) => < Home {...props }
                            rif = { defrif } razon = {defrazon} token={deftoken} page ="home"
                            /> } />

                    <Route 
                        path = '/charts'
                        render = {
                           
                            (props) => < Home {...props }
                            rif = { defrif } razon = {defrazon} token={deftoken} page ="charts"
                            /> } />    

                            <Box pt = { 4 } >
                                <Copyright />
                            </Box> 
                        </Container> 
                        </main> 
                    </div>
                );
            }