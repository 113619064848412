import React from 'react'
import Christmass from '../../../assets/christmass.png'
import { makeStyles,withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom'
const useStyles = makeStyles(theme => ({
    root: { 
        height: '100vh'
    },
    Christmass:{
        display:'absolute',
        float:"right",
        paddingTop:100,
        height:500
    },
    title:{
        position:'absolute',
        top: "30%",
        textAlign:"left",
        verticalAlign: "middle",
        color:"gray",
        fontSize:60
    },
    explanation:{
        position:'absolute',
        top: "42%",
        textAlign:"left",
        verticalAlign: "middle",
        color:"gray",
        fontSize:23
    }
  }));
export default function NotFound (props){
    const classes = useStyles();
    let history = useHistory();
    const NotFound = withStyles(theme => ({
        root: {
          position:'absolute',
           
          borderRadius:'20px',
          border:'2px  solid #1f2d3d ',
          top: "60%",
          
          color:"#1f2d3d", 
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: '#000048',
            color:"white"
          },
  
        },
      }))(Button);
      const gotohome = (e) => {
        history.push('/login')
        
      };
    return(
        <Grid container  component="main" className={classes.root} >
            <Grid item xs={12} sm={8} md={6}    >
                <img src = {Christmass} className = {classes.Christmass} alt="404"></img>
            </Grid>
            
            <Grid item xs={12} sm={8} md={6}    >
                <h1 className = {classes.title}>Error 404!</h1>
                <p className = {classes.explanation}>Parece que santa se equivocó de casa!<br></br>seguro que ingresaste la dirección correcta?<br></br> intenta ingresar aqui</p>
                <NotFound className= "btn_pos" variant = "outlined" onClick={gotohome} >
                    Ir a página principal
                </NotFound>
            </Grid>

            
        </Grid>
        
            
                //
           
       
    )
}