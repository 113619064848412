import React from 'react'
import { BrowserRouter,Switch} from 'react-router-dom';
import SignInSide from '../sign-in-side/SignInSide'
import SignUp from '../sign-up/SignUp';
import ForgotPassword from '../password/ForgotPassword';
import ResetPassword from '../password/ResetPassword';
import AppliedRoute from './AppliedRoute'
import Dashboard from '../dashboard/Dashboard'
//import Charts from '../dashboard/Charts';
import GetStarted from '../sign-up/getStarted'
import NotFound from '../dashboard/componentsSPA/ErrorsComponents.js/NotFound'

export default function Routes({ appProps }) {
    return (
    <BrowserRouter>
      <Switch>
        <AppliedRoute path="/" exact component={SignInSide} appProps={appProps}  />
        <AppliedRoute path="/login" exact component={SignInSide} appProps={appProps} />
        <AppliedRoute path="/signup" exact component={SignUp} appProps={appProps} />
        <AppliedRoute path="/forgotpassword" exact component={ForgotPassword} appProps={appProps} />
        <AppliedRoute path="/resetpassword/:token/:mail" exact component={ResetPassword} appProps={appProps} />
        <AppliedRoute path ="/home" exact component={Dashboard} appProps={appProps} />
        <AppliedRoute path ="/charts" exact component={Dashboard} appProps={appProps} />
        <AppliedRoute path ="/create" exact component={Dashboard} appProps={appProps} />
        <AppliedRoute path = "/getStarted" exact component ={GetStarted} appProps={appProps}></AppliedRoute>
        { <AppliedRoute component = {NotFound}/>/* Finally, catch all unmatched routes */ }
        
        </Switch>
      </BrowserRouter>  
    );
  }
