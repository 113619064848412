import React from 'react';
import { ResponsiveContainer } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import Plink from './img/plinklittle.png';

export default function Header() {

  return (
    <React.Fragment>
      {/* <Title>Today</Title> */}
      <ResponsiveContainer>
        <Container>
          <Row>
              <Col>
                  <Row>
                  <img src={Plink} className="img_pos" alt="plink"  />
                    <div className="divcontainer">
                      <label className="title_pos">Plink</label>
                    </div>
                  </Row >
                  <Row>
                    <label className="label1_pos" >Gestionar de forma segura el acceso a los recursos y servicios de Plink Dashboard. Toma el control de tus operaciones y descarga tus reportes.</label>
                  </Row>
              </Col>
          </Row>
        </Container>

      </ResponsiveContainer>
    </React.Fragment>
  );
}