import React , { useState }from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {BaseURL, addNewUserAdmin, MasterKeyLogin} from '../constants/constants'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nextpos.us/">
        NextPOS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1f2d3d",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor : "#1f2d3d"
  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function SignUp(props) {
  const classes = useStyles();
  const [idcommerce, setIdCommerce] = useState("")
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("")
  const [loader,setLoader]=useState(false)
  const [emptyIdCommerce,setEmptyIdCommerce] = useState(false)
  const [emptyMail,setEmptyMail] = useState(false)
  const [emptyPassword,setEmptyPassword]= useState(false)
  const [msg, setMsgs]= useState("")
  const [open, setOpen] =useState(false);
  const [onCheck,setOnChecked]=useState(false)

  function handleClick(event){
        var isidEmpty = isEmpty(idcommerce,"id_commerce")
        var ismailEmpty = isEmpty(email,"mail")
        var isPassEmpty = isEmpty(password,"password")

        if(isidEmpty ||ismailEmpty || isPassEmpty||  onCheck ===false){
          return
        }

        var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
        var apiBaseUrl =BaseURL;
        var payload={
          "id_commerce":idcommerce,
          "mail":email,
          "password":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
        axios.post(apiBaseUrl+addNewUserAdmin, payload)
        
        .then(function (response) {
              setLoader(false)
              if(response.data.Exito){
                //setMsgs(response.data.Message)
                props.history.push({
                  pathname: '/getStarted',
                  state: { user: email, password: cipherpass.toString()}
                })
                handleClickOpen()
              }
              else{   
                setMsgs(response.data.Message)
                handleClickOpen()
              }
              
        })
        .catch(function (error) {
          setLoader(false)
          setMsgs("Error en el servidor")
        });
  }

  function isEmpty(field,type){
    if(field===""){
      if(type === "id_commerce"){
        setEmptyIdCommerce(true)
        return true
      }else if(type === "mail"){
        setEmptyMail(true)
        return true
      }else if(type ==="password"){
        setEmptyPassword(true)
        return true
      }
      return true
    }else{
      if(type === "id_commerce"){
        setEmptyIdCommerce(false)
        return false
      }else if(type === "mail"){
        setEmptyMail(false)
        return false
      }else if( type ==="password"){
        setEmptyPassword(false)
        return false
      }
      return false
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("idCommerce").value = ""
    document.getElementById("email").value = ""
    document.getElementById("password").value = ""
    setIdCommerce("")
    setEmail("")
    setPassword("")
  };
    return (
      <React.Fragment>
      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registro
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error = {emptyIdCommerce}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="idCommerce"
                  label="ID Comercio"
                  autoFocus
                  disabled = {loader}
                  inputProps={{maxLength: 6}}
                  onChange = {e => setIdCommerce(e.target.value)}
                  
                  
                />
               
                <Link href="https://www.mercantilbanco.com/mercprod/index.html" variant="body2" >
                ¿No tienes un Id de comercio? Click aquí
                </Link>
                
                 
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  error = {emptyMail}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  disabled = {loader}
                  inputProps={{maxLength: 30}}
                  onChange = {e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error = {emptyPassword}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  disabled = {loader}
                  inputProps={{maxLength:10}}
                  onChange = {e => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" onClick = {e=>setOnChecked(true)}/>}
                  label="Acepto las políticas de privacidad, términos y condiciones de la plataforma NextPOS ®."
                />
              </Grid>
            </Grid>
            <Button
              
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled ={loader}
              onClick={(event) => handleClick(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Crear cuenta
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="./login" variant="body2">
                ¿Ya tienes una cuenta? Iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      </React.Fragment>
    );
  
}
