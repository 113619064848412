import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


import {BaseURL, loginApi, MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CryptoJS from 'crypto-js'
import superagent from 'superagent'
import { useMediaQuery } from 'react-responsive'
import MailIcon from '@material-ui/icons/MailOutline';
import InputAdornment from '@material-ui/core/InputAdornment';

import bgVisa from '../assets/bg-visa.jpg';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {''}
      <Link color="inherit" href="https://nextpos.us/">
        from NextPOS ®
      </Link>{' '}
      {new Date().getFullYear()} 
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgVisa})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    
  },
  
  paper2: {
    margin: theme.spacing(25, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    color:"white"
  },

  paper: {
    margin: theme.spacing(10, 7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  },
  
  inputText:{
    borderColor:'#6730e3'
  },

  title:{
    fontWeight:"bolder",
    marginLeft:"15%"
  },
  paragraph:{
    marginTop:'30px',
    marginLeft:'15%',
    marginRight:'15%',
    fontWeight:'400'
  },
  gridLogin:{
    backgroundColor: "#f4f7fa"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#6730e3",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  subtitleLogin:{
    marginTop:'5px',
    color:"#707070"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor : "#6730e3"
  },
  buttonProgress: {
    color: "#6730e3",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  iconmail:{
    color:"#6730e3"
  }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function SignInSide(props) {
  const classes = useStyles();
  let history = useHistory();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [open, setOpen] =useState(false);
  const [loader,setLoader]=useState(false)
  const [emptyMail,setEmptyMail] = useState(false)
  const [emptyPassword,setEmptyPassword]= useState(false)

  if(localStorage.getItem("_CDAUPOMGKLJL") !=null || sessionStorage.getItem("_CDAUPOMGKLJL")){
    history.push("/home");
  }

  function handleClick(event){
        event.preventDefault()
        var ismailEmpty = isEmpty(email,"mail")
        var isPassEmpty = isEmpty(password,"password")
        //let keepSessionActived = document.getElementById("keepSession")

        if(ismailEmpty || isPassEmpty ){
          return
        }
        var ciphertext = CryptoJS.AES.encrypt(email, MasterKeyLogin);
        var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
        var apiBaseUrl =BaseURL;
        var payload={
          "Username":ciphertext.toString(),//"email.username",
          "Clave":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
       
        superagent
            .post(apiBaseUrl+loginApi)
            .send(payload) // sends a JSON post body
            .then(response =>{
              setLoader(false)
             
              if(response.body.exito){
                
                let id = response.body.data_usuario.id_usuario
                let rif = response.body.data_usuario.rif
                let razon_social = response.body.data_usuario.razon_social
                let url_image = response.body.data_usuario.url_image
                let token = response.body.token
                var cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                var cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                var cryptorazon = CryptoJS.AES.encrypt(razon_social.toString(), MasterKeyLogin);
                var cryptoimage = CryptoJS.AES.encrypt(url_image.toString(), MasterKeyLogin);
                
               
                    sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHhkuiok",cryptorazon.toString())
                    sessionStorage.setItem("_{}KRTKLMNHFGFBJNMKKDKDDM",token.toString())
                    sessionStorage.setItem("_{}4RThhUJHHUKNMJKK",cryptoimage.toString())

                history.push("/home");
              }
              else{   
                handleClickOpen()
              }
            })
            .catch(error =>{
              setLoader(false)
              alert(error)
            })
  
  
  }

  function isEmpty(field,type){
    if(field===""){
      if(type === "mail"){
        setEmptyMail(true)
        return true
      }else if(type ==="password"){
        setEmptyPassword(true)
        return true
      }
      return true
    }else{
      if(type === "mail"){
        setEmptyMail(false)
        return false
      }else if( type ==="password"){
        setEmptyPassword(false)
        return false
      }
      return false
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    
    <React.Fragment>
    
    <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Usuario o contraseña inválido
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>

    <Grid container  component="main" className={classes.root} >
      
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image}  >
        {
          isDesktopOrLaptop &&
          <div className={classes.paper2}>
            <Typography className={classes.title}  component="h1" variant="h3">
              Panel de control para los comercios.
            </Typography>
            <Typography className={classes.paragraph}  component="h1" variant="h6">
              Gestiona las transacciones asociadas a tu comercio a través de tu link de pago, genera reportes y monitorea el rendimiento de tus ventas en cualquier rango de fecha.
            </Typography>
          </div>
         
            
        }

      </Grid>

      <Grid className={classes.gridLogin} item xs={12} sm={8} md={4} component={Paper} elevation={6}  square>
        <div className={classes.paper}>
         
          <Typography component="h1" variant="h4">
            Login
          </Typography>
          <Typography  className = {classes.subtitleLogin}component="h5" variant="h9">
            Accede a nuestro administrador gratis!
          </Typography>
          <form className={classes.form} >
            <TextField
              error = {emptyMail}
              variant="outlined"
              margin="normal"
              color = "#6730e3"
              required
              fullWidth
              id="email"
              label="Usuario"
              name="email"
              onChange ={e => setEmail(e.target.value)}
              disabled = {loader}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon className = {classes.iconmail} />
                  </InputAdornment>
                ),
              }}
              helperText={emptyMail?"Campo vacio":""}
              
            />
            <TextField
              className = {classes.inputText}
              error = {emptyPassword}
              variant="outlined"
              margin="normal"
              color = "#6730e3"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              disabled = {loader}
              onChange ={e => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon className = {classes.iconmail} />
                  </InputAdornment>
                ),
              }}
              helperText={emptyPassword?"Campo vacio":""}
              
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled = {loader}
              onClick={(event) => handleClick(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Iniciar sesión
              
            </Button>
            
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    
    </React.Fragment>

  );
}
