import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import LogoDpos from './img/plinklittle.png'
import MercantilLogo from './img/mercantilLogo.png'


const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff"
    },
    barHeader:{
        height:10,
        backgroundColor:"#6730E3",
        marginLeft:30,
        marginRight:30,
        marginTop:30
    },
    
    header: {
        alignSelf : "center",
        display: "flex",
        marginLeft: 5,
        //backgroundColor:"#3f51b5",
        height:100,
        width:500,
        marginTop:10,
        flexDirection:"row"
    },
    imageDPOSHeader:{
        width:150,
        height:150,
        alignSelf:"flex-start",
       
    }, 
    imageMercantilHeader:{
        width:150,
        alignSelf:"flex-end",
        marginLeft:150,
        marginBottom:10
    }, 
    date:{
        alignSelf : "center",
        display: "flex",
        marginLeft: 5,
        height:40,
        width:500,
        marginTop:10,
        
    },
    dateText:{
        alignSelf:"flex-end",
        fontSize : 10
    },
    title:{
        alignSelf : "center",
        display: "flex",
        marginLeft: 5,
        height:40,
        width:500,
        
    },

    textTitle:{
        fontSize: 16,
        fontWeight:"extrabold",
        alignSelf:"flex-start",
        marginLeft:5
    },

    textSubTitle:{
        fontSize: 13,
        fontWeight:"extrabold",
        alignSelf:"flex-start",
        marginLeft:5,
        marginTop:10,
        color:"#7a7a7a"
    },
    
    headerTable:{
        alignSelf : "center",
        display: "flex",
        marginTop:50,
        backgroundColor:"#6730E3",
        height:40,
        flexDirection:"row",
        fontSize:11,
        color:"white",
        width:550,
        paddingTop:10,
        paddingLeft:20,
    },
    dateTable:{
        marginRight:90
    },
    amountTable:{
        marginRight:20
    },
    aprobTable:{
        marginRight:20
    },
    refTable:{
        marginRight:20
    },
    lottTable:{
        marginRight:20
    },
    cardTable:{
        marginRight:20
    },

    tableContainer: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        padding: 5,
        width:550,
        marginLeft:30,
        marginRight:30,
        alignSelf:"center",
        borderTop: 1 ,
        borderLeft:1,
        borderRight:1,
        borderColor:"#9c9c9c"
    },
    tableDetails: {
        display: "flex",
        marginLeft: 5,
        flexDirection: "row",

    },
    tableDate: {
        fontSize: 9,
        border:"1px solid "
    },

    tableAmount:{
        fontSize: 9,
        paddingRight: 40
    },
    tableAprob:{
        fontSize: 9,
        paddingLeft:20,
        paddingRight: 40
    },
    tableRef:{
        fontSize: 9,
        paddingLeft:20,
        paddingRight: 40
    },
    tableLot:{
        fontSize: 9,
        paddingLeft:20,
        paddingRight: 40
    },
    tableCard:{
        fontSize: 9,
        paddingLeft:20,
        paddingRight: 40
    },
    detailsFooter: {
        display: "flex",
        flexDirection: "row"
    },
  
});

export default function PdfDocument(props) {
    let razon = props.razon
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    let completedate = `${date}${"/"}${month<10?`0${month}`:`${month}`}${"/"}${year}${"  "}${hour}${":"}${minute}`
    return (
        <Document>
            <Page style={styles.page}>
                        <View style={styles.barHeader} fixed></View>
                        <View style={styles.header} fixed>
                            <Image style = {styles.imageDPOSHeader}src = {LogoDpos}></Image>  
                            <Image style = {styles.imageMercantilHeader}src = {MercantilLogo}></Image> 
                        </View>
                        
                        <View style={styles.date} fixed>
                            <Text style={styles.dateText}>{completedate}</Text>   
                        </View>

                        <View style={styles.title} fixed>
                            <Text style={styles.textTitle}>Reporte de transacciones</Text> 
                            <Text style={styles.textSubTitle}>Empresa {razon}.</Text> 
                            
                        </View>

                        <View style={styles.headerTable} fixed>
                            <Text style={styles.dateTable}>Fecha</Text> 
                            <Text style={styles.amountTable}>Monto Bs.</Text>
                            <Text style={styles.aprobTable}>Nro Aprobación</Text>
                            <Text style={styles.refTable}>Referencia</Text>
                            <Text style={styles.lottTable}>Lote</Text>
                            <Text style={styles.cardTable}>Últimos 4 dígitos</Text> 
                        </View>
                {props.data.data
                    ? props.data.data.map((a, index) => {
                            return (
                                <View key={index} style={styles.tableContainer}>
                                    
                                    <View style={styles.tableDetails}>
                                        <Text style={styles.tableDate}>{a.fecha}</Text>
                                        <Text style={styles.tableAmount}>{a.monto}</Text>
                                        <Text style={styles.tableAprob}>{a.aprobacion}</Text>
                                        <Text style={styles.tableRef}>{a.num_referencia}</Text>
                                        <Text style={styles.tableLot}>{a.lote}</Text>
                                        <Text style={styles.tableCard}>{a.ultimos4_digitos}</Text>
                                    </View>
                                   
                                    
                                </View>
                );
                })
            : ""}
            </Page>
        </Document>
    );
}